:root {
  --main-color: #c1b033;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--main-color);
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: var(--main-color);
}

.ant-steps-item-process .ant-steps-item-icon {
  background: var(--main-color);
  border-color: var(--main-color);
}
