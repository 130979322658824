.logo-search-wrapper .react-autosuggest__container {
  width: 70%;
  max-width: 440px;
  position: relative;
}

.logo-search-wrapper .react-autosuggest__input {
  width: 100%;
  height: 60px;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)
}

.logo-search-wrapper .react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  width: 98%;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)

}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 15px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #F1F1F1;
}


@media only screen and (max-width: 480px) {
  .logo-search-wrapper .react-autosuggest__container {
    width: 92%;
  }
}