:root {
  --main-color: #ddc23d;
}

.ant-btn-primary,
.ant-btn-primary:active,
.ant-btn-primary:hover {
  background-color: #ddc23d !important;
  border-color: #ddc23d !important;
}

.ant-btn-link {
  color: rgba(0, 0, 0, 0.54) !important;
}

.ant-btn-link:hover {
  color: #ddc23d !important;
}

.ant-spin-dot-item {
  background-color: #ddc23d !important;
}

.ant-btn-dashed:active {
  color: #ddc23d !important;
  background-color: #fff;
  border-color: #ddc23d !important;
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #ddc23d !important;
  background-color: #fff;
  border-color: #ddc23d !important;
}

.ant-list-item {
  transition: box-shadow 0.4s;
  box-shadow: 'none';
}

.ant-list-item:hover {
  box-shadow: 0px 8px 20px 2px #c9c9c9;
}

.ant-comment-actions > li > span:hover {
  color: #ddc23d !important;
}

#abaca-layout .ant-pagination-item-active {
  border-color: #ddc23d;
}

#abaca-layout .ant-pagination-item-active:focus,
#abaca-layout .ant-pagination-item-active:hover {
  border-color: #ddc23d;
}

#abaca-layout .ant-pagination-item-active a {
  color: #ddc23d;
}

#abaca-layout .ant-pagination-item {
  color: rgba(0, 0, 0, 0.54);
}

body .ant-calendar-picker-container {
  z-index: 1400;
}

.switch-wrapper .ant-switch-checked {
  background-color: #ddc23d;
}

.selected-month-wrapper .anticon-left-circle:hover,
.selected-month-wrapper .anticon-right-circle:hover {
  cursor: pointer;
  color: var(--main-color);
}

.ant-steps.ant-steps-navigation .ant-steps-item::before {
  background-color: #ddc23d;
}

.ant-steps-item.ant-steps-item-wait .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  box-shadow: -1px 0 0 0 var(--main-color) !important;
}
