.swiper-container {
  margin: 30px auto;
  max-width: 94%;
}

.swiper-container .swiper-slide {
  align-items: center; display: flex; justify-content: center;
}

.swiper-container .swiper-button-next, .swiper-container .swiper-button-prev {
  background-image: none; width: 44px; height: 46px;
}